import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateCoinTypeForm = _resolveComponent("CreateCoinTypeForm")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mb-5 text-center" }, [
      _createElementVNode("h1", null, "Create Coin Type"),
      _createElementVNode("div", null, [
        _createElementVNode("small", null, "Coins added here are available for users.")
      ])
    ], -1)),
    _createVNode(_component_CreateCoinTypeForm)
  ]))
}