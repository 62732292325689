import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center my-5" }
const _hoisted_2 = { class: "card col-12 col-md-6 col-lg-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DualRingLoader, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("form", {
              class: "",
              onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Submit && _ctx.Submit(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", {
                    for: "coinName",
                    class: "form-label"
                  }, "Coin Name", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.name
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
                        type: "text",
                        placeholder: "Bitcoin",
                        class: "form-control",
                        id: "coinName",
                        required: ""
                      }, null, 512), [
                        [
                          _vModelText,
                          _ctx.formData.name,
                          void 0,
                          { number: true }
                        ]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", {
                    for: "coin_code",
                    class: "form-label"
                  }, "Coin code", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.code
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.code) = $event)),
                        type: "text",
                        placeholder: "BTC",
                        class: "form-control",
                        id: "coin_code",
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.formData.code]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", {
                    for: "usdRate",
                    class: "form-label"
                  }, "USD Rate", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.usdRate
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.usdRate) = $event)),
                        type: "number",
                        placeholder: "0.00",
                        class: "form-control",
                        id: "usdRate",
                        step: "any",
                        required: ""
                      }, null, 512), [
                        [
                          _vModelText,
                          _ctx.formData.usdRate,
                          void 0,
                          { number: true }
                        ]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-center" }, [
                _createElementVNode("button", { class: "btn btn-dark" }, "Create Coin Type")
              ], -1))
            ], 32)
          ])
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}